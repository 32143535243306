import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';


export const getEligibleRoomsForUser = async (
  userId: string,
): Promise<TelesittingTypes.Room[]> => {
  try {
    const res = await Api.get<TelesittingTypes.Room[]>(
      `telesitting/v1/rooms/manage/${userId}`,
    );

    return res && res?.data;
  } catch (error) {
    console.error("error requesting user's potential rooms");
    throw error;
  }
};
