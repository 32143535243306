import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getRoomsByOrg } from '../data-access/getRoomsByOrg';

interface Variables {
  orgId?: string;
  includeDeleted?: boolean;
}

export const useOrgRooms = ({ orgId, includeDeleted }: Variables) => {
  const { data, isError, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['rooms', orgId, includeDeleted],
      queryFn: ({ pageParam }) =>
        getRoomsByOrg({
          orgId: orgId as string,
          includeDeleted,
          lastKey: pageParam,
        }),
      enabled: orgId !== undefined,
      getNextPageParam: (lastPage) => lastPage.lastKey,
      initialPageParam: undefined as string | undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  const rooms = useMemo(
    () => data?.pages.flatMap((page) => page.rooms) ?? [],
    [data],
  );

  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [data]);

  return { rooms, isError, isLoading: hasNextPage || isFetching };
};
