import { forwardRef, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid';
import { isEmpty, isNil } from 'lodash';

import { DomainId } from '@inspiren-monorepo/util-rooms';

import { extractRoomNumberFromRoomId } from '../../../../../utility/helpers/id';
import { useOrgRooms } from '../../../hooks/useOrgRooms';
import { useFieldValidator } from '../../ImportTable/hooks/useFieldValidator';

const ImportRoomSelect = forwardRef((params: GridRenderEditCellParams, ref) => {
  const apiRef = useGridApiContext();

  const { id, error: validationError, disabled } = params;

  const selectedOrg = apiRef.current.state.editRows[id]?.organization?.value;

  const selectedBuildingFloorUnit =
    apiRef.current.state.editRows[id]?.buildingFloorUnit?.value;

  const { isLoading, rooms } = useOrgRooms({ orgId: selectedOrg });

  const valueOptions = useMemo(() => {
    if (isEmpty(selectedOrg) || isEmpty(selectedBuildingFloorUnit) || !rooms) {
      return [];
    }

    return (rooms || [])
      .filter(
        (room) =>
          DomainId.toUnitId(room.domainId) ===
          `${selectedOrg}-${selectedBuildingFloorUnit}`,
      )
      .map((room) => extractRoomNumberFromRoomId(room.domainId) || '')
      .sort();
  }, [rooms, selectedOrg, selectedBuildingFloorUnit]);

  const { invalidValueError } = useFieldValidator({
    skipValidation:
      isLoading || isNil(selectedOrg) || isNil(selectedBuildingFloorUnit),
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...params.colDef,
      valueOptions,
    },

    disabled:
      disabled || !selectedOrg || !selectedBuildingFloorUnit || isLoading,
    startAdornment: !selectedOrg && isLoading && (
      <CircularProgress color='secondary' size={18} />
    ),
    error: invalidValueError || !!validationError,
  };

  return <GridEditSingleSelectCell {...selectParams} ref={ref} />;
});

export default ImportRoomSelect;
