import { useCallback } from 'react';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { putRoom, postRoom } from '../../../../../utility';
import { updateRoomsQueryData } from '../../../../../utility/helpers/updateRoomsQueryData';

import type { RoomsTableQueryResponse } from '../../../data-access/getRoomsByOrg';
import type { OnSubmitFormModal } from '../../../modals/FormModalBase';
import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const useRoomsUpsert = (unitMap: Record<string, AdminTypes.UnitDto>) => {
  const queryClient = useQueryClient();

  const handleEditSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async ({
      id,
      roomId: roomNumber,
      organization,
      unitId,
      fallRiskLevel,
      careLevel,
      displayName,
      designation,
      hide,
      deleted,
    }) => {
      const unit = unitMap[unitId];

      const data: AdminTypes.EditRoom = {
        id,
        domainId: `Room-${unit.domainId}-${roomNumber}`,
        fallRiskLevel: fallRiskLevel === 'high' ? 'medium' : fallRiskLevel,
        careLevelId: careLevel || undefined,
        displayName,
        hide,
        deleted,
        designation,
        unitId,
      };

      const updatedRoom = await putRoom(data);

      updateRoomsQueryData(queryClient, organization, updatedRoom);
    },
    [unitMap],
  );

  const handleAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async ({
      roomId,
      organization,
      unitId,
      fallRiskLevel,
      careLevel,
      displayName,
      designation,
      hide,
      deleted = false,
    }) => {
      const unit = unitMap[unitId];

      const data: AdminTypes.NewRoom = {
        domainId: `Room-${unit.domainId}-${roomId}`,
        fallRiskLevel: fallRiskLevel === 'high' ? 'medium' : fallRiskLevel,
        careLevelId: careLevel || undefined,
        displayName,
        hide,
        deleted,
        designation,
        unitId,
      };

      const newRoom = await postRoom(data);

      queryClient.setQueryData(
        ['rooms', organization, true],
        (oldData: InfiniteData<RoomsTableQueryResponse>) => {
          if (!oldData) {
            // Handle the case where oldData is undefined
            // For example, you might want to return an empty array
            return {
              pages: [],
              pageParams: [],
            };
          }

          const firstPage = { ...oldData.pages[0] };
          firstPage.rooms = [newRoom, ...firstPage.rooms];

          return {
            ...oldData,
            pages: [firstPage, ...oldData.pages.slice(1, oldData.pages.length)],
          };
        },
      );
    },
    [unitMap],
  );

  return {
    handleEditSubmit,
    handleAddSubmit,
  };
};

export default useRoomsUpsert;
