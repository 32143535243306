import type { MouseEvent } from 'react';
import { useCallback, useState, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import toast from 'react-hot-toast';

import { FALL_RISK_OPTIONS } from '@inspiren-monorepo/shared-types';

import FallRiskToggleButton from './FallRiskToggleButton';
import FallRiskToggleButtonGroup from './FallRiskToggleButtonGroup';

import { useCurrentUser } from '../../../../../HOC/CurrentUserContextProvider';
import { sendAmpEvent } from '../../../../../utility/amplitude';
import { updateRoomsQueryData } from '../../../../../utility/helpers/updateRoomsQueryData';
import { updateFallRisk } from '../data-access/updateFallRisk';

import type { FallRiskLevel } from '../../../../../../types';

const options = [
  FALL_RISK_OPTIONS.off,
  FALL_RISK_OPTIONS.nightLow,
  FALL_RISK_OPTIONS.low,
  FALL_RISK_OPTIONS.medium,
];

interface Props {
  orgId: string;
  roomId: string;
  roomDomainId: string;
  roomName: string;
  fallRiskLevel: FallRiskLevel;
  nightLowFallRisk: boolean;
}

const FallRiskToggle = ({
  orgId,
  roomId,
  roomDomainId,
  roomName,
  fallRiskLevel = 'off',
  nightLowFallRisk,
}: Props) => {
  const fallRiskOptions = useMemo(() => {
    if (!nightLowFallRisk) {
      return [
        FALL_RISK_OPTIONS.off,
        FALL_RISK_OPTIONS.low,
        FALL_RISK_OPTIONS.medium,
      ];
    }

    return options;
  }, [nightLowFallRisk]);

  // Which fall risk level is currently being updated
  const [loading, setLoading] = useState<FallRiskLevel | null>(null);

  const { user } = useCurrentUser();
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: updateFallRisk,
    onMutate: ({ fallRiskLevel: newFallRisk }) => setLoading(newFallRisk),
    onSuccess: async (_data, { fallRiskLevel: newFallRisk }) => {
      sendAmpEvent('Update Fall Risk', {
        roomId: roomDomainId,
        fallRiskLevel: newFallRisk,
      });

      await queryClient.cancelQueries({ queryKey: ['customUnits'] });

      updateRoomsQueryData(queryClient, orgId, {
        domainId: roomDomainId,
        fallRiskLevel: newFallRisk,
      });
    },
    onError: () => toast.error('Could not update fall risk sensitivity'),
    onSettled: () => setLoading(null),
  });

  const handleChange = useCallback(
    async (_event: MouseEvent, newFallRisk: FallRiskLevel) => {
      if (isNil(newFallRisk) || newFallRisk === fallRiskLevel) return;

      if (
        newFallRisk === 'off' &&
        !user?.ability?.can('view', 'global.fall-risk-off')
      ) {
        toast.error('Please contact manager to turn off fall risk');
        return;
      }

      try {
        await confirm({
          description:
            newFallRisk === 'off'
              ? `Are you sure alerts should only be sent for falls for ${roomName}?`
              : `Are you sure ${roomName} is a ${FALL_RISK_OPTIONS[
                  newFallRisk
                ].label.toLowerCase()} fall risk?`,
          confirmationText: 'Yes',
        });

        mutate({ roomId, fallRiskLevel: newFallRisk });
      } catch {
        // Do nothing. `confirm` will throw if the user cancels, which
        // is fine, and `mutate` is handled by its `onError` callback.
      }
    },
    [fallRiskLevel],
  );

  return (
    <>
      <Typography variant='subtitle2' textAlign='center'>
        Fall Risk Sensitivity
      </Typography>
      <FallRiskToggleButtonGroup
        exclusive
        size='medium'
        value={fallRiskLevel}
        onChange={handleChange}
      >
        {fallRiskOptions.map((option) => (
          <FallRiskToggleButton
            key={option.value}
            fallRiskLevel={option.value}
            loading={loading === option.value && isPending}
          />
        ))}
      </FallRiskToggleButtonGroup>
    </>
  );
};

export default FallRiskToggle;
