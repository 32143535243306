
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { UnitRes } from '../../../types';
import type { RoomsTableQueryResponse } from '../../components/Admin/data-access/getRoomsByOrg';
import type { InfiniteData, QueryClient } from '@tanstack/react-query';

export const updateRoomsQueryData = (
  queryClient: QueryClient,
  organization: string,
  data: Partial<AdminTypes.Room>,
) => {
  queryClient.setQueryData(
    ['rooms', organization, true],
    (oldData: InfiniteData<RoomsTableQueryResponse>) => {
      if (!oldData) {
        // Handle the case where oldData is undefined
        // For example, you might want to return an empty array
        return {
          pages: [],
          pageParams: [],
        };
      }

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          rooms: page.rooms.map((room: AdminTypes.Room) => {
            if (room.domainId === data.domainId) {
              return {
                ...room,
                ...data,
                careLevelId: data.careLevelId || undefined,
              };
            }

            return room;
          }),
        })),
      };
    },
  );

  const queryKeys = queryClient.getQueryCache().findAll({
    queryKey: ['customUnits'],
  });

  queryKeys.forEach((key) => {
    queryClient.setQueryData<UnitRes>(
      key.queryKey,
      (prev: UnitRes | undefined) => {
        if (!prev) return undefined;

        return {
          ...prev,
          rooms: prev.rooms.map((room) =>
            room.domainId === data.domainId ? { ...room, ...data } : room,
          ),
        };
      },
    );
  });

  queryClient.invalidateQueries({
    queryKey: ['customUnits'],
  });
};
