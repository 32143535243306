import { queryOptions, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { getCustomUnit } from '../data-access/getCustomUnit';

export const getRoomsQueryOptions = (unitDomainId: string) =>
  queryOptions({
    queryKey: ['customUnits', unitDomainId],
    queryFn: () => getCustomUnit(unitDomainId),
    enabled: !isEmpty(unitDomainId),
    refetchInterval: 3000,
  });

export const useRooms = (unitDomainId: string) =>
  useQuery(getRoomsQueryOptions(unitDomainId));
